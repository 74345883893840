<template>
  <router-view v-if="isReady" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { ReportApiService } from '@hypefactors/shared/js/services/api/ReportApiService'

export default {
  name: 'MeasureIndex',

  data () {
    return {
      isReady: false
    }
  },

  computed: {
    ...mapGetters('measure', ['report']),

    reportIdFromRoute () {
      return this.$route.params.reportId
    }
  },

  watch: {
    $route (to, from) {
      if (from.name === 'measure.manage' && to.name === 'measure.view') {
        this.fetchInitialData()
      }
    }
  },

  created () {
    if (!this.reportIdFromRoute) {
      this.$notify.error('You can\'t access this page directly!')

      return this.$router.push({ name: 'home' })
    }

    this.fetchInitialData()
  },

  methods: {
    ...mapActions('measure', [
      'setReport',
      'syncUiFromReport'
    ]),

    async fetchReport () {
      await ReportApiService.fetch(this.reportIdFromRoute)
        .then((report) => {
          this.setReport(report)

          this.syncUiFromReport(report)
        })
    },

    async fetchInitialData () {
      const loading = this.$loading({
        lock: true,
        text: this.$t('Loading the Report')
      })

      try {
        await this.fetchReport()
      } catch (err) {
        loading.close()

        this.$notify.error('The Report you\'re trying to access does not exist!')

        return this.$router.push({ name: 'home' })
      }

      if (this.report.status === 'done' && this.$route.name !== 'measure.view') {
        return this.$router.push({
          name: 'measure.view',
          params: { reportId: this.report.id }
        })
      }

      // TODO: Remove this at a latter time...
      this.$store.commit('coverage/SET_BOOTSTRAPPED', true)

      this.isReady = true

      loading.close()
    }
  }
}
</script>
